import React, { useState } from "react";
import { GlassMagnifier } from "react-image-magnifiers";
import useWindowSize from "../../../hooks/useWindowSize";
import CollapsibleItem from "./collapsible-item/CollapsibleItem";
import { items } from "./items";
import {
  RoadmapWrapper,
  VideoWrapper,
  MapWrapper,
  Line,
  Card,
  Title,
  VideoBorder,
  RmImageWrapper,
  Button,
  ButtonWrapper,
} from "./Roadmap.style";

const Roadmap = ({ data }) => {
  const size = useWindowSize();
  const isMobile = size.width < 450;
  const isTabPort = size.width < 900 && size.width > 450;
  let width = isMobile ? size.width - 20 : "700";
  const height = isMobile ? "260" : "500";
  const [isActive, setIsActive] = useState(false);
  if (!size.width) return <></>;
  if (!isTabPort && !isMobile) {
    width = "900";
  }

  return (
    <RoadmapWrapper id="roadmap">
      <Title>{data.roadmap_title}</Title>
      <Line />
      <RmImageWrapper>
        <GlassMagnifier imageSrc={data.roadmap_image.url} imageAlt="Example" />
      </RmImageWrapper>
      <Line />
      <ButtonWrapper>
        <Button href={data.roadmap_download_link.url} target="_blank">
          {data.download_roadmap_image_label}
        </Button>
        <Button href={data.roadmap_file_link.url} target="_blank">
          {data.roadmap_button_label}
        </Button>
      </ButtonWrapper>
    </RoadmapWrapper>
  );
};

export default Roadmap;
