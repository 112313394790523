import React from "react";
import { GalleryWrapper, GalleryImage, Row } from "./Gallery.style";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css"; //Allows for server-side rendering.
import useWindowSize from "../../../hooks/useWindowSize";
const Gallery = () => {
  const size = useWindowSize();
  const isMobile = size.width < 450;

  const options = {
    items: isMobile ? 2 : 5,
    nav: true,
    rewind: true,
    autoplay: true,
    loop: true,
    autoplayTimeout: 1250,
  };
  const options2 = {
    items: isMobile ? 2 : 5,
    nav: true,
    rewind: true,
    autoplay: true,
    loop: true,
    rtl: true,
    autoplayTimeout: 1250,
  };
  return (
    <GalleryWrapper>
      <OwlCarousel options={options}>
        <GalleryImage src="gallery-1.png" />
        <GalleryImage src="gallery-2.png" />
        <GalleryImage src="gallery-3.png" />
        <GalleryImage src="gallery-4.png" />
        <GalleryImage src="gallery-5.png" />
        <GalleryImage src="gallery-6.png" />
        <GalleryImage src="gallery-7.png" />
        <GalleryImage src="gallery-8.png" />
        <GalleryImage src="gallery-9.png" />
        <GalleryImage src="gallery-10.png" />
      </OwlCarousel>
      <OwlCarousel options={options2}>
        <GalleryImage src="gallery-11.png" />
        <GalleryImage src="gallery-12.png" />
        <GalleryImage src="gallery-13.png" />
        <GalleryImage src="gallery-14.png" />
        <GalleryImage src="gallery-15.png" />
        <GalleryImage src="gallery-16.png" />
      </OwlCarousel>
    </GalleryWrapper>
  );
};

export default Gallery;
