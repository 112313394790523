import React, { useState, useEffect, useRef } from "react";
import Select, { components } from "react-select";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DetailModal from "./detail-modal/DetailModal";

import {
  GalleryWrapper,
  ImagesWrapper,
  ItemWrapper,
  NftImage,
  DetailsWrapper,
  HeaderWrapper,
  ItemTitle,
  Number,
  SolScanLink,
  FiltersWrapper,
  FiltersTitle,
  Images,
  ImagesHeader,
  ImagesTitle,
  TabButton,
  SelectedFilters,
  SelectedFiltersLabel,
  SelectedFiltersLabelWrapper,
  NumFilters,
  customSelectStyles,
  SelectFilters,
  sortSelectStyles,
  Option,
  PaginationWrapper,
  PaginationAction,
  PageNumber,
  ClearFilters,
  Tabs,
  FiltersButton,
  TitleWrapper,
} from "./Gallery.style";
const sortOptions = [
  { label: "Default", value: "default" },
  { label: "Rarity ↑", value: "rare" },
  { label: "Rarity ↓", value: "common" },
  { label: "Number ↑", value: "highest" },
  { label: "Number ↓", value: "lowest" },
];
import {
  backgroundOptions,
  hatOptions,
  earringsOptions,
  mouthOptions,
  clothesOptions,
  eyesOptions,
  furOptions,
} from "./filters";

import {
  backgroundOptions as backgroundTigerOptions,
  hatOptions as hatTigerOptions,
  earringsOptions as earringsTigerOptions,
  mouthOptions as mouthTigerOptions,
  clothesOptions as clothesTigerOptions,
  eyesOptions as eyesTigerOptions,
  furOptions as furTigerOptions,
} from "./tigers-filters";
import useWindowSize from "../../hooks/useWindowSize";

const traits = {
  Background: backgroundOptions,
  Hat: hatOptions,
  Earrings: earringsOptions,
  Mouth: mouthOptions,
  Eyes: eyesOptions,
  Fur: furOptions,
  Clothes: clothesOptions,
};

const Gallery = () => {
  const [apesData, setApesData] = useState([]);
  const [initApesData, setInitApesData] = useState([]);
  const [tigersData, setTigersData] = useState([]);
  const [itemsToShow, setItemsToShow] = useState([]);
  const [numberFilters, setNumberFilters] = useState([]);
  const [filtersEmpty, setFiltersEmpty] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedNft, setSelectedNft] = useState(false);

  const [totalPages, setTotalPages] = useState(250);

  const size = useWindowSize();
  const isMobile = size.width < 900;

  const [showFilters, setShowFilters] = useState(false);

  const bgFilterRef = useRef();
  const clothesFilterRef = useRef();
  const earringsFilterRef = useRef();
  const eyesFilterRef = useRef();
  const furFilterRef = useRef();
  const mouthFilterRef = useRef();
  const hatFilterRef = useRef();
  const [sortBy, setSortBy] = useState(sortOptions[0]);
  const [pageNumber, setPageNumber] = useState(1);

  const [selectedTab, setSelectedTab] = useState("apes");

  const [filters, setFilters] = useState({
    Background: [],
    Hat: [],
    Earrings: [],
    Mouth: [],
    Eyes: [],
    Fur: [],
    Clothes: [],
  });

  const fetchData = async () => {
    const apes = await axios.get("apes-data.json").then((data) => data.data);
    const tigers = await axios
      .get("tigers-data.json")
      .then((data) => data.data);
    setInitApesData(apes);
    setTigersData(tigers);
    setTotalPages(Math.ceil(apes.length / 20));
    setApesData(apes);
    setItemsToShow(apes);
  };

  useEffect(() => {
    // let earringsOpts = [];
    // let Backgrounds = [];
    // let Eyes = [];
    // let Mouth = [];
    // let Clothes = [];
    // let Fur = [];
    // let Earrings = [];
    // let Hat = [];
    // const fetchData = async () => {
    //   const data = await axios
    //     .get("tigers-data.json")
    //     .then((data) => data.data);
    // data.forEach((item, i) => {
    //   let backgrounds = item.attributes.find(
    //     (a) => a.trait_type === "Background"
    //   );
    //   backgroundOptions.find(
    //     (bg) => bg.value === backgrounds.value
    //   ).rarity += 1;
    //   let eyes = item.attributes.find((a) => a.trait_type === "Eyes");
    //   eyesOptions.find((bg) => bg.value === eyes.value).rarity += 1;
    //   if (!Backgrounds.find((b) => b.value === backgrounds.value)) {
    //     Backgrounds.push({
    //       label: backgrounds.value,
    //       value: backgrounds.value,
    //     });
    //   }
    //   if (!Eyes.find((b) => b.value === eyes.value)) {
    //     Eyes.push({
    //       label: eyes.value,
    //       value: eyes.value,
    //     });
    //   }
    //   let fur = item.attributes.find((a) => a.trait_type === "Fur");
    //   furOptions.find((bg) => bg.value === fur.value).rarity += 1;
    //   if (!Fur.find((b) => b.value === fur.value)) {
    //     Fur.push({
    //       label: fur.value,
    //       value: fur.value,
    //     });
    //   }
    //   let clothes = item.attributes.find((a) => a.trait_type === "Clothes");
    //   clothesOptions.find((bg) => bg.value === clothes.value).rarity += 1;
    //   if (!Clothes.find((b) => b.value === clothes.value)) {
    //     Clothes.push({
    //       label: clothes.value,
    //       value: clothes.value,
    //     });
    //   }
    //   let mouth = item.attributes.find((a) => a.trait_type === "Mouth");
    //   mouthOptions.find((bg) => bg.value === mouth.value).rarity += 1;
    //   if (!Mouth.find((b) => b.value === mouth.value)) {
    //     Mouth.push({
    //       label: mouth.value,
    //       value: mouth.value,
    //     });
    //   }
    //   let earrings = item.attributes.find((a) => a.trait_type === "Earrings");
    //   earringsOptions.find((bg) => bg.value === earrings.value).rarity += 1;
    //   if (!Earrings.find((b) => b.value === earrings.value)) {
    //     Earrings.push({
    //       label: earrings.value,
    //       value: earrings.value,
    //     });
    //   }
    //   let hat = item.attributes.find((a) => a.trait_type === "Hat");
    //   if (!Hat.find((b) => b.value === hat.value)) {
    //     Hat.push({
    //       label: hat.value,
    //       value: hat.value,
    //     });
    //   }
    // });
    // console.log(
    //   JSON.stringify(
    //     data.map((item) => {
    //       let rarity = getRarity(item.attributes);
    //       return {
    //         ...item,
    //         rarity: rarity,
    //       };
    //     })
    //   )
    // );
    // console.log(JSON.stringify(hatOptions));
    // console.log("\n");
    // console.log(JSON.stringify(eyesOptions));
    // console.log("\n");
    // console.log(JSON.stringify(furOptions));
    // console.log("\n");
    // console.log(JSON.stringify(clothesOptions));
    // console.log("\n");
    // console.log(JSON.stringify(mouthOptions));
    // console.log("\n");
    // console.log(JSON.stringify(earringsOptions));
    // console.log("\n");
    // console.log(JSON.stringify(backgroundOptions));
    // console.log("\n");
    // console.log(
    //   JSON.stringify(
    //     data.map((item) => {
    //       let rarity = getRarity(item.attributes);
    //       return {
    //         ...item,
    //         rarity: rarity,
    //       };
    //     })
    //   )
    // );
    //   setApesData(data);
    //   setItemsToShow(data);
    // };
    fetchData();
  }, []);

  const getRarity = (attributes) => {
    let res = 0;
    attributes.forEach((attr, i) => {
      res += traits?.[attr.trait_type]?.find(
        (trait) => trait.value === attr.value
      )?.rarity;
    });
    // console.log(res);
    return res;
  };

  const CustomOption = ({ children, innerProps, isDisabled, ...props }) => {
    return !isDisabled ? (
      <Option {...innerProps}>
        {children} ({props.data.rarity})
      </Option>
    ) : null;
  };

  const Control = ({ children, selectProps, ...props }) => {
    // @ts-ignore
    const style = { cursor: "pointer" };
    return (
      <components.Control {...props}>
        <img width="20px" src={selectProps.imgUrl} />
        {children}
      </components.Control>
    );
  };

  const handleFiltersChanged = (newValue, action) => {
    if (action.action === "clear") {
      setFilters({
        ...filters,
        [action.name]: [],
      });
    }
    const filterItems = newValue.map((f) => f.value);
    if (action.action === "select-option") {
      setFilters({
        ...filters,
        [action.name]: filterItems,
      });
    }
    setFiltersEmpty(isFiltersClear());
  };

  const isFiltersClear = () => {
    let hasValue = true;
    Object.keys(filters).forEach((key, i) => {
      if (filters[key].length > 0) hasValue = false;
    });
    setFiltersEmpty(hasValue);
    return hasValue;
  };

  useEffect(() => {
    if (isFiltersClear()) {
      setNumberFilters(0);
      handleSortBy(sortBy, apesData, setItemsToShow);
      setTotalPages(Math.ceil(apesData.length / 20));
    } else {
      let numFilters = 0;
      Object.keys(filters).forEach((key) => {
        if (filters[key].length > 0) numFilters += 1;
      });
      const res = apesData.filter((ape, index) => {
        let isValid = true;
        Object.keys(filters).forEach((key, i) => {
          if (filters[key].length > 0 && isValid) {
            const attr = ape.attributes.find((a) => a.trait_type === key);
            if (filters[key].find((filter) => filter === attr.value)) {
              isValid = true;
            } else isValid = false;
          }
        });
        return isValid;
      });
      setNumberFilters(numFilters);
      handleSortBy(sortBy, res, setItemsToShow);
      setTotalPages(Math.ceil(res.length / 20));
    }
  }, [filters]);

  const clearFilters = () => {
    bgFilterRef.current?.clearValue();
    mouthFilterRef.current?.clearValue();
    eyesFilterRef.current?.clearValue();
    hatFilterRef.current?.clearValue();
    clothesFilterRef.current?.clearValue();
    furFilterRef.current?.clearValue();
    earringsFilterRef.current?.clearValue();

    setFilters({
      Background: [],
      Hat: [],
      Earrings: [],
      Mouth: [],
      Eyes: [],
      Fur: [],
    });

    setFiltersEmpty(true);
    setNumberFilters(0);
    setPageNumber(1);
  };

  const handleSortBy = (newValue, list, setList) => {
    if (newValue.value === "highest") {
      const sortdRes = JSON.parse(JSON.stringify(list)).sort((a, b) => {
        if (parseInt(a.name.split("#")[1]) > parseInt(b.name.split("#")[1]))
          return -1;
        else return 1;
      });
      setList(sortdRes);
    } else if (newValue.value === "lowest") {
      const sortdRes = JSON.parse(JSON.stringify(list)).sort((a, b) => {
        if (parseInt(a.name.split("#")[1]) < parseInt(b.name.split("#")[1]))
          return -1;
        else return 1;
      });
      setList(sortdRes);
    } else if (newValue.value === "default") {
      setList(list);
    } else if (newValue.value === "rare") {
      const sortdRes = JSON.parse(JSON.stringify(list)).sort((a, b) => {
        if (parseInt(a.rarity) > parseInt(b.rarity)) return 1;
        else return -1;
      });
      setList(sortdRes);
    } else if (newValue.value === "common") {
      const sortdRes = JSON.parse(JSON.stringify(list)).sort((a, b) => {
        if (parseInt(a.rarity) > parseInt(b.rarity)) return -1;
        else return 1;
      });
      setList(sortdRes);
    }
    setSortBy(newValue);
    setPageNumber(1);
  };

  const paginate = (action) => {
    if (action === "next") {
      setPageNumber(pageNumber + 1);
    } else if (action === "back") {
      setPageNumber(pageNumber - 1);
    } else if (action === "first") {
      setPageNumber(1);
    } else if (action === "last") {
      setPageNumber(totalPages);
    }
    window.scrollTo(0, 0);
  };

  const handleSwapTabs = (tab) => {
    setSelectedTab(tab);
    if (tab === "apes") {
      setApesData(initApesData);
      setItemsToShow(initApesData);
    } else {
      setApesData(tigersData);
      setItemsToShow(tigersData);
    }
    clearFilters();
  };

  const handleOpenModal = (nft) => {
    setSelectedNft(nft);
    setShowModal(true);
  };

  const filtersComponent = (
    <FiltersWrapper showFilters={!isMobile || showFilters}>
      {!isMobile && <FiltersTitle>Filter</FiltersTitle>}
      <SelectFilters>
        <Select
          className="gallery__select"
          name="Background"
          options={
            selectedTab === "apes" ? backgroundOptions : backgroundTigerOptions
          }
          styles={customSelectStyles}
          placeholder="Background"
          isClearable
          components={{ Option: CustomOption, Control }}
          isMulti
          imgUrl="background-icon.png"
          onChange={handleFiltersChanged}
          ref={bgFilterRef}
          isSearchable={false}
        />
        <Select
          className="gallery__select"
          name="Clothes"
          options={
            selectedTab === "apes" ? clothesOptions : clothesTigerOptions
          }
          styles={customSelectStyles}
          placeholder="Clothes"
          isClearable
          components={{ Option: CustomOption, Control }}
          isMulti
          imgUrl="clothes-icon.png"
          onChange={handleFiltersChanged}
          ref={clothesFilterRef}
          isSearchable={false}
        />
        <Select
          className="gallery__select"
          name="Earrings"
          options={
            selectedTab === "apes" ? earringsOptions : clothesTigerOptions
          }
          styles={customSelectStyles}
          placeholder="Earrings"
          isClearable
          components={{ Option: CustomOption, Control }}
          isMulti
          imgUrl="earrings-icon.png"
          onChange={handleFiltersChanged}
          ref={earringsFilterRef}
          isSearchable={false}
        />
        <Select
          className="gallery__select"
          name="Eyes"
          options={selectedTab === "apes" ? eyesOptions : eyesTigerOptions}
          styles={customSelectStyles}
          placeholder="Eyes"
          isClearable
          components={{ Option: CustomOption, Control }}
          isMulti
          imgUrl="eyes-icon.png"
          onChange={handleFiltersChanged}
          ref={eyesFilterRef}
          isSearchable={false}
        />
        <Select
          className="gallery__select"
          name="Fur"
          options={selectedTab === "apes" ? furOptions : furTigerOptions}
          styles={customSelectStyles}
          placeholder="Fur"
          isClearable
          components={{ Option: CustomOption, Control }}
          isMulti
          imgUrl="fur-icon.png"
          onChange={handleFiltersChanged}
          ref={furFilterRef}
          isSearchable={false}
        />
        <Select
          className="gallery__select"
          name="Hat"
          options={selectedTab === "apes" ? hatOptions : hatTigerOptions}
          styles={customSelectStyles}
          placeholder="Hat"
          isClearable
          components={{ Option: CustomOption, Control }}
          isMulti
          imgUrl="hat-icon.png"
          onChange={handleFiltersChanged}
          ref={hatFilterRef}
          isSearchable={false}
        />
        <Select
          className="gallery__select"
          name="Mouth"
          options={selectedTab === "apes" ? mouthOptions : mouthTigerOptions}
          styles={customSelectStyles}
          placeholder="Mouth"
          isClearable
          components={{ Option: CustomOption, Control }}
          isMulti
          imgUrl="mouth-icon.png"
          onChange={handleFiltersChanged}
          ref={mouthFilterRef}
          isSearchable={false}
        />
        <ClearFilters disabled={filtersEmpty} onClick={clearFilters}>
          Clear Filters
        </ClearFilters>
      </SelectFilters>
    </FiltersWrapper>
  );

  return (
    <GalleryWrapper>
      {!isMobile && filtersComponent}
      <Images>
        <ImagesHeader>
          <TitleWrapper>
            <ImagesTitle>Baby Ape {itemsToShow.length}</ImagesTitle>
          </TitleWrapper>
          <Tabs>
            <TabButton
              isActive={selectedTab === "apes"}
              onClick={() => handleSwapTabs("apes")}
            >
              Baby Apes
            </TabButton>
            <TabButton
              isActive={selectedTab === "tigers"}
              onClick={() => handleSwapTabs("tigers")}
            >
              Baby Tigers
            </TabButton>
            <FiltersButton
              onClick={() => setShowFilters(!showFilters)}
              src="filter-icon.png"
            />
          </Tabs>
          {isMobile && filtersComponent}
        </ImagesHeader>
        <SelectedFilters>
          <SelectedFiltersLabelWrapper>
            <SelectedFiltersLabel>Filters</SelectedFiltersLabel>
            <NumFilters>{numberFilters}</NumFilters>
          </SelectedFiltersLabelWrapper>
          <SelectedFiltersLabelWrapper>
            <SelectedFiltersLabel>Sort:</SelectedFiltersLabel>
            <Select
              className="gallery__select"
              defaultValue={sortOptions[0]}
              name="sort"
              options={sortOptions}
              styles={sortSelectStyles}
              onChange={(value) =>
                handleSortBy(value, itemsToShow, setItemsToShow)
              }
              isSearchable={false}
            />
          </SelectedFiltersLabelWrapper>
        </SelectedFilters>
        <ImagesWrapper>
          {itemsToShow
            .slice((pageNumber - 1) * 20, pageNumber * 20)
            .map((item) => {
              return (
                <ItemWrapper key={item.name}>
                  <NftImage
                    onClick={() => handleOpenModal(item)}
                    src={item.src}
                  />
                  <DetailsWrapper>
                    <HeaderWrapper>
                      <ItemTitle>{item.name}</ItemTitle>
                      <SolScanLink
                        href={`https://solscan.io/token/${item.mint}`}
                        target="_blank"
                      >
                        <FontAwesomeIcon color="white" size="sm" icon="link" />
                      </SolScanLink>
                    </HeaderWrapper>
                  </DetailsWrapper>
                </ItemWrapper>
              );
            })}
        </ImagesWrapper>
        <PaginationWrapper>
          <PaginationAction
            onClick={() => paginate("first")}
            disabled={pageNumber === 1}
          >
            <FontAwesomeIcon icon="angle-double-left" size="lg" />
          </PaginationAction>
          <PaginationAction
            onClick={() => paginate("back")}
            disabled={pageNumber === 1}
          >
            <FontAwesomeIcon icon="angle-left" size="lg" />
          </PaginationAction>
          <PageNumber>
            {pageNumber} of {totalPages}
          </PageNumber>
          <PaginationAction
            onClick={() => paginate("next")}
            disabled={pageNumber === totalPages}
          >
            <FontAwesomeIcon icon="angle-right" size="lg" />
          </PaginationAction>
          <PaginationAction
            onClick={() => paginate("last")}
            disabled={pageNumber === totalPages}
          >
            <FontAwesomeIcon icon="angle-double-right" size="lg" />
          </PaginationAction>
        </PaginationWrapper>
      </Images>
      {showModal && (
        <DetailModal
          modalIsOpen={showModal}
          setIsOpen={setShowModal}
          data={selectedNft}
        />
      )}
    </GalleryWrapper>
  );
};

export default Gallery;
