import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const MeetTheTeamWrapper = styled.div`
  max-width: 1426px;
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 70px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 0 10px;
  width: fit-content;
  @media (max-width: 450px) {
    font-size: 40px;
  }
`;

export const TeamWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  padding: 0 40px;
  margin: 0 auto;
  justify-content: center;
  @media (max-width: 450px) {
    width: fit-content;
    gap: 10px;
    padding: 0 20px;
  }
`;

export const NftImage = styled.img`
  border-radius: 20px;
  width: 290px;
  height: auto;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const InfoWrapper = styled.div``;

export const MemberWrapper = styled.div`
  max-width: 290px;
  @media (max-width: 450px) {
    margin: auto;
    max-width: calc(50% - 20px);
  }
`;

export const MemberTitle = styled.h3`
  font-size: 28px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;

  margin: 5px 0;

  @media (max-width: 450px) {
    font-size: 16px;
  }
`;

export const MemberTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SocialIcon = styled.img`
  @media (max-width: 450px) {
    width: 30px;
  }
`;

export const SocialLink = styled.a``;

export const Subtitle = styled.div`
  font-size: 19px;
  font-family: "Oswald";
  color: rgba(255, 255, 255, 0.6);

  margin: 5px 0;

  @media (max-width: 450px) {
    font-size: 13px;
  }
`;

export const Description = styled.div`
  font-size: 15px;
  font-family: "Poppins";
  color: rgba(255, 255, 255, 0.6);

  @media (max-width: 450px) {
    font-size: 12px;
  }
`;
