import React from "react";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Home from "./views/home/Home";
import { Route, Routes } from "react-router-dom";
import Story from "./views/story/Story";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faTimes,
  faUser,
  faShoppingBag,
  faBars,
  faLink,
  faChevronLeft,
  faChevronRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Gallery from "./views/gallery/Gallery";
import Merch from "./views/merch/Merch";
import { useSinglePrismicDocument } from "@prismicio/react";
library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faTimes,
  faUser,
  faShoppingBag,
  faBars,
  faLink,
  faChevronLeft,
  faChevronRight,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faMinus,
  faPlus
);
function App() {
  const [home] = useSinglePrismicDocument("homepage");
  const [story] = useSinglePrismicDocument("story");
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home data={home?.data} />} />
        <Route path="/story" element={<Story data={story?.data} />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/merch" element={<Merch />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
