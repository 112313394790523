export const backgroundOptions = [
  { label: "Gray", value: "Gray", rarity: 613 },
  { label: "Dark Blue", value: "Dark Blue", rarity: 613 },
  { label: "Mustard", value: "Mustard", rarity: 638 },
  { label: "Yellow", value: "Yellow", rarity: 632 },
  { label: "Purple", value: "Purple", rarity: 646 },
  { label: "Aquamarine", value: "Aquamarine", rarity: 663 },
  { label: "Orange", value: "Orange", rarity: 576 },
  { label: "Baby Blue", value: "Baby Blue", rarity: 607 },
  { label: "Space Pirate", value: "Space Pirate", rarity: 1 },
  { label: "Reaper", value: "Reaper", rarity: 1 },
  { label: "Diamond", value: "Diamond", rarity: 1 },
  { label: "Zeus", value: "Zeus", rarity: 1 },
  { label: "Monkey King", value: "Monkey King", rarity: 1 },
  { label: "Acid", value: "Acid", rarity: 1 },
  { label: "Skeleton", value: "Skeleton", rarity: 1 },
  { label: "Fire", value: "Fire", rarity: 1 },
  { label: "Demon", value: "Demon", rarity: 1 },
  { label: "Angry Viking", value: "Angry Viking", rarity: 1 },
  { label: "Ice", value: "Ice", rarity: 1 },
  { label: "Plague Doctor", value: "Plague Doctor", rarity: 1 },
];

export const eyesOptions = [
  { label: "Crazy", value: "Crazy", rarity: 222 },
  { label: "Sleepy", value: "Sleepy", rarity: 248 },
  { label: "Awww", value: "Awww", rarity: 226 },
  { label: "Bored", value: "Bored", rarity: 864 },
  { label: "Heart Glasses", value: "Heart Glasses", rarity: 193 },
  { label: "Angry", value: "Angry", rarity: 181 },
  { label: "3D", value: "3D", rarity: 252 },
  { label: "Roll", value: "Roll", rarity: 213 },
  { label: "Blindfold", value: "Blindfold", rarity: 122 },
  { label: "<3<3", value: "<3<3", rarity: 83 },
  { label: "Sad", value: "Sad", rarity: 240 },
  { label: "Laser Eyes", value: "Laser Eyes", rarity: 33 },
  { label: "Wide Eyed", value: "Wide Eyed", rarity: 222 },
  { label: "Holographic", value: "Holographic", rarity: 85 },
  { label: "Bloodshot", value: "Bloodshot", rarity: 235 },
  { label: "Closed", value: "Closed", rarity: 217 },
  { label: "Sunglasses", value: "Sunglasses", rarity: 165 },
  { label: "Steampunk", value: "Steampunk", rarity: 63 },
  { label: "x_x", value: "x_x", rarity: 144 },
  { label: "Robot", value: "Robot", rarity: 161 },
  { label: "Zombie", value: "Zombie", rarity: 163 },
  { label: "Neon Party", value: "Neon Party", rarity: 252 },
  { label: "Eyepatch", value: "Eyepatch", rarity: 137 },
  { label: "Hypnotized", value: "Hypnotized", rarity: 94 },
  { label: "Scumbag", value: "Scumbag", rarity: 97 },
  { label: "Blue Beams", value: "Blue Beams", rarity: 23 },
  { label: "Cyborg", value: "Cyborg", rarity: 53 },
  { label: "Space Pirate", value: "Space Pirate", rarity: 1 },
  { label: "Reaper", value: "Reaper", rarity: 1 },
  { label: "Diamond", value: "Diamond", rarity: 1 },
  { label: "Zeus", value: "Zeus", rarity: 1 },
  { label: "Monkey King", value: "Monkey King", rarity: 1 },
  { label: "Acid", value: "Acid", rarity: 1 },
  { label: "Skeleton", value: "Skeleton", rarity: 1 },
  { label: "Fire", value: "Fire", rarity: 1 },
  { label: "Demon", value: "Demon", rarity: 1 },
  { label: "Angry Viking", value: "Angry Viking", rarity: 1 },
  { label: "Ice", value: "Ice", rarity: 1 },
  { label: "Plague Doctor", value: "Plague Doctor", rarity: 1 },
];

export const furOptions = [
  { label: "White", value: "White", rarity: 217 },
  { label: "Dark Brown", value: "Dark Brown", rarity: 670 },
  { label: "Black", value: "Black", rarity: 668 },
  { label: "Cream", value: "Cream", rarity: 351 },
  { label: "Tan", value: "Tan", rarity: 366 },
  { label: "Blue", value: "Blue", rarity: 290 },
  { label: "Golden Brown", value: "Golden Brown", rarity: 454 },
  { label: "Gray", value: "Gray", rarity: 269 },
  { label: "DMT", value: "DMT", rarity: 126 },
  { label: "Pink", value: "Pink", rarity: 272 },
  { label: "Red", value: "Red", rarity: 254 },
  { label: "Zombie", value: "Zombie", rarity: 191 },
  { label: "Cheetah", value: "Cheetah", rarity: 256 },
  { label: "Robot", value: "Robot", rarity: 157 },
  { label: "Death Bot", value: "Death Bot", rarity: 119 },
  { label: "Prism", value: "Prism", rarity: 44 },
  { label: "Trippy", value: "Trippy", rarity: 29 },
  { label: "Silver", value: "Silver", rarity: 40 },
  { label: "Noise", value: "Noise", rarity: 79 },
  { label: "Camo", value: "Camo", rarity: 56 },
  { label: "24K Gold", value: "24K Gold", rarity: 30 },
  { label: "Cosmic", value: "Cosmic", rarity: 41 },
  { label: "Brown", value: "Brown", rarity: 9 },
  { label: "Space Pirate", value: "Space Pirate", rarity: 1 },
  { label: "Reaper", value: "Reaper", rarity: 1 },
  { label: "Diamond", value: "Diamond", rarity: 1 },
  { label: "Zeus", value: "Zeus", rarity: 1 },
  { label: "Monkey King", value: "Monkey King", rarity: 1 },
  { label: "Acid", value: "Acid", rarity: 1 },
  { label: "Skeleton", value: "Skeleton", rarity: 1 },
  { label: "Fire", value: "Fire", rarity: 1 },
  { label: "Demon", value: "Demon", rarity: 1 },
  { label: "Angry Viking", value: "Angry Viking", rarity: 1 },
  { label: "Ice", value: "Ice", rarity: 1 },
  { label: "Plague Doctor", value: "Plague Doctor", rarity: 1 },
];

export const clothesOptions = [
  { label: "None", value: "None", rarity: 841 },
  { label: "Black T", value: "Black T", rarity: 166 },
  { label: "Tweed Suit", value: "Tweed Suit", rarity: 78 },
  { label: "Toga", value: "Toga", rarity: 88 },
  { label: "Hip Hop", value: "Hip Hop", rarity: 72 },
  { label: "Guayabera", value: "Guayabera", rarity: 113 },
  { label: "Tuxedo T", value: "Tuxedo T", rarity: 128 },
  { label: "Biker Vest", value: "Biker Vest", rarity: 135 },
  { label: "Wool Turtleneck", value: "Wool Turtleneck", rarity: 108 },
  { label: "Flannel Shirt", value: "Flannel Shirt", rarity: 113 },
  { label: "Puffy Vest", value: "Puffy Vest", rarity: 114 },
  { label: "Caveman Pelt", value: "Caveman Pelt", rarity: 80 },
  { label: "Stunt Jacket", value: "Stunt Jacket", rarity: 93 },
  { label: "Pink Dress", value: "Pink Dress", rarity: 36 },
  { label: "Hawaiian", value: "Hawaiian", rarity: 156 },
  { label: "Admiral's Coat", value: "Admiral's Coat", rarity: 33 },
  { label: "Logo Black T", value: "Logo Black T", rarity: 168 },
  { label: "Blue Bib", value: "Blue Bib", rarity: 110 },
  { label: "Prison Uniform", value: "Prison Uniform", rarity: 121 },
  { label: "Black T Holes", value: "Black T Holes", rarity: 95 },
  { label: "Cowboy Shirt", value: "Cowboy Shirt", rarity: 58 },
  { label: "Sailor Shirt", value: "Sailor Shirt", rarity: 141 },
  { label: "Bone Tee", value: "Bone Tee", rarity: 116 },
  { label: "Fast Food Uniform", value: "Fast Food Uniform", rarity: 60 },
  { label: "Army Jacket", value: "Army Jacket", rarity: 112 },
  { label: "Striped T", value: "Striped T", rarity: 210 },
  { label: "Tie Dye", value: "Tie Dye", rarity: 69 },
  { label: "Bones Necklace", value: "Bones Necklace", rarity: 105 },
  { label: "Space Suit", value: "Space Suit", rarity: 54 },
  { label: "Smoking Jacket", value: "Smoking Jacket", rarity: 99 },
  { label: "Rainbow Suspenders", value: "Rainbow Suspenders", rarity: 70 },
  { label: "Leather Punk Jacket", value: "Leather Punk Jacket", rarity: 73 },
  { label: "Red T", value: "Red T", rarity: 74 },
  { label: "Tank Top", value: "Tank Top", rarity: 121 },
  { label: "Leather Jacket", value: "Leather Jacket", rarity: 102 },
  { label: "Lab Coat", value: "Lab Coat", rarity: 71 },
  { label: "Bandolier", value: "Bandolier", rarity: 75 },
  { label: "King's Robe", value: "King's Robe", rarity: 28 },
  { label: "Pimp Coat", value: "Pimp Coat", rarity: 36 },
  { label: "Sleevesless Logo T", value: "Sleevesless Logo T", rarity: 65 },
  { label: "Sleevesless T", value: "Sleevesless T", rarity: 133 },
  { label: "Prom Dress", value: "Prom Dress", rarity: 43 },
  { label: "Work Vest", value: "Work Vest", rarity: 80 },
  { label: "White Bib", value: "White Bib", rarity: 114 },
  { label: "Space Pirate", value: "Space Pirate", rarity: 1 },
  { label: "Black Suit", value: "Black Suit", rarity: 31 },
  { label: "Reaper", value: "Reaper", rarity: 1 },
  { label: "Diamond", value: "Diamond", rarity: 1 },
  { label: "Zeus", value: "Zeus", rarity: 1 },
  { label: "Monkey King", value: "Monkey King", rarity: 1 },
  { label: "Acid", value: "Acid", rarity: 1 },
  { label: "Skeleton", value: "Skeleton", rarity: 1 },
  { label: "Fire", value: "Fire", rarity: 1 },
  { label: "Demon", value: "Demon", rarity: 1 },
  { label: "Angry Viking", value: "Angry Viking", rarity: 1 },
  { label: "Ice", value: "Ice", rarity: 1 },
  { label: "Plague Doctor", value: "Plague Doctor", rarity: 1 },
];

export const mouthOptions = [
  { label: "Smug", value: "Smug", rarity: 276 },
  { label: "Party Horn", value: "Party Horn", rarity: 38 },
  { label: "Smile", value: "Smile", rarity: 94 },
  { label: "Cigarette", value: "Cigarette", rarity: 300 },
  { label: "Dumbfounded", value: "Dumbfounded", rarity: 220 },
  { label: "Bored", value: "Bored", rarity: 1926 },
  { label: "Kazoo", value: "Kazoo", rarity: 42 },
  { label: "Ooo", value: "Ooo", rarity: 107 },
  { label: "Grin", value: "Grin", rarity: 295 },
  { label: "Flower", value: "Flower", rarity: 25 },
  { label: "Pout", value: "Pout", rarity: 276 },
  { label: "Rainbow Grill", value: "Rainbow Grill", rarity: 38 },
  { label: "Pipe", value: "Pipe", rarity: 50 },
  { label: "Jovial", value: "Jovial", rarity: 131 },
  { label: "Pacifier", value: "Pacifier", rarity: 190 },
  { label: "Discomfort", value: "Discomfort", rarity: 72 },
  { label: "Toast", value: "Toast", rarity: 21 },
  { label: "Grin Gold Grill", value: "Grin Gold Grill", rarity: 45 },
  { label: "Wah", value: "Wah", rarity: 58 },
  { label: "Candy Cane", value: "Candy Cane", rarity: 45 },
  { label: "Cookie", value: "Cookie", rarity: 49 },
  { label: "Blowpipe", value: "Blowpipe", rarity: 25 },
  { label: "Rage", value: "Rage", rarity: 121 },
  { label: "Tongue Out", value: "Tongue Out", rarity: 65 },
  { label: "Small Grin", value: "Small Grin", rarity: 119 },
  { label: "Kek", value: "Kek", rarity: 88 },
  { label: "Bubble Gum", value: "Bubble Gum", rarity: 37 },
  { label: "Ohhh", value: "Ohhh", rarity: 94 },
  { label: "Cigar", value: "Cigar", rarity: 41 },
  { label: "Blue Grill", value: "Blue Grill", rarity: 45 },
  { label: "Lip Bite", value: "Lip Bite", rarity: 17 },
  { label: "Space Pirate", value: "Space Pirate", rarity: 1 },
  { label: "Pizza", value: "Pizza", rarity: 19 },
  { label: "Dagger", value: "Dagger", rarity: 19 },
  { label: "Reaper", value: "Reaper", rarity: 1 },
  { label: "Diamond", value: "Diamond", rarity: 1 },
  { label: "Zeus", value: "Zeus", rarity: 1 },
  { label: "Monkey King", value: "Monkey King", rarity: 1 },
  { label: "Acid", value: "Acid", rarity: 1 },
  { label: "Skeleton", value: "Skeleton", rarity: 1 },
  { label: "Fire", value: "Fire", rarity: 1 },
  { label: "Demon", value: "Demon", rarity: 1 },
  { label: "Angry Viking", value: "Angry Viking", rarity: 1 },
  { label: "Ice", value: "Ice", rarity: 1 },
  { label: "Plague Doctor", value: "Plague Doctor", rarity: 1 },
];

export const earringsOptions = [
  { label: "None", value: "None", rarity: 3487 },
  { label: "Silver Hoop", value: "Silver Hoop", rarity: 443 },
  { label: "Gold Hoop", value: "Gold Hoop", rarity: 246 },
  { label: "Silver Stud", value: "Silver Stud", rarity: 404 },
  { label: "Cross", value: "Cross", rarity: 64 },
  { label: "Gold Stud", value: "Gold Stud", rarity: 219 },
  { label: "Diamond Stud", value: "Diamond Stud", rarity: 125 },
  { label: "Space Pirate", value: "Space Pirate", rarity: 1 },
  { label: "Reaper", value: "Reaper", rarity: 1 },
  { label: "Diamond", value: "Diamond", rarity: 1 },
  { label: "Zeus", value: "Zeus", rarity: 1 },
  { label: "Monkey King", value: "Monkey King", rarity: 1 },
  { label: "Acid", value: "Acid", rarity: 1 },
  { label: "Skeleton", value: "Skeleton", rarity: 1 },
  { label: "Fire", value: "Fire", rarity: 1 },
  { label: "Demon", value: "Demon", rarity: 1 },
  { label: "Angry Viking", value: "Angry Viking", rarity: 1 },
  { label: "Ice", value: "Ice", rarity: 1 },
  { label: "Plague Doctor", value: "Plague Doctor", rarity: 1 },
];

export const hatOptions = [
  { label: "Sea Captain Hat", value: "Sea Captain Hat", rarity: 135 },
  { label: "Halo", value: "Halo", rarity: 170 },
  { label: "None", value: "None", rarity: 1165 },
  { label: "Safari", value: "Safari", rarity: 89 },
  { label: "Irish Boho", value: "Irish Boho", rarity: 118 },
  { label: "Bowler", value: "Bowler", rarity: 111 },
  { label: "Stuntman Helmet", value: "Stuntman Helmet", rarity: 79 },
  { label: "Cat", value: "Cat", rarity: 80 },
  { label: "WW2 Pilot Helmet", value: "WW2 Pilot Helmet", rarity: 66 },
  { label: "Ushanka", value: "Ushanka", rarity: 129 },
  { label: "Beanie", value: "Beanie", rarity: 267 },
  { label: "Black Hat", value: "Black Hat", rarity: 107 },
  { label: "Prussian Helmet", value: "Prussian Helmet", rarity: 66 },
  { label: "Panda", value: "Panda", rarity: 89 },
  { label: "Army Hat", value: "Army Hat", rarity: 148 },
  { label: "Bandana", value: "Bandana", rarity: 76 },
  { label: "Seal", value: "Seal", rarity: 81 },
  { label: "Short Mohawk", value: "Short Mohawk", rarity: 173 },
  { label: "Bunny Ears", value: "Bunny Ears", rarity: 100 },
  { label: "Spinner Hat", value: "Spinner Hat", rarity: 91 },
  { label: "Cowboy Hat", value: "Cowboy Hat", rarity: 197 },
  { label: "Seaman Hat", value: "Seaman Hat", rarity: 216 },
  { label: "Baby's Bonnet", value: "Baby's Bonnet", rarity: 80 },
  { label: "Girl's Hair Short", value: "Girl's Hair Short", rarity: 77 },
  {
    label: "Police Motorcycle Helmet",
    value: "Police Motorcycle Helmet",
    rarity: 73,
  },
  { label: "Fisherman Hat", value: "Fisherman Hat", rarity: 155 },
  { label: "Girl's Hair Pink", value: "Girl's Hair Pink", rarity: 67 },
  { label: "Pengu", value: "Pengu", rarity: 88 },
  { label: "Trippy Captain", value: "Trippy Captain", rarity: 34 },
  { label: "Fez", value: "Fez", rarity: 178 },
  { label: "Sushi Chef Headband", value: "Sushi Chef Headband", rarity: 83 },
  { label: "Blue Party Hat", value: "Blue Party Hat", rarity: 49 },
  { label: "Laurel Wreath", value: "Laurel Wreath", rarity: 31 },
  { label: "Devil Horns", value: "Devil Horns", rarity: 123 },
  { label: "Yellow Party Hat", value: "Yellow Party Hat", rarity: 46 },
  { label: "Camo Helmet", value: "Camo Helmet", rarity: 124 },
  { label: "King's Crown", value: "King's Crown", rarity: 27 },
  { label: "Space Pirate", value: "Space Pirate", rarity: 1 },
  { label: "Reaper", value: "Reaper", rarity: 1 },
  { label: "Diamond", value: "Diamond", rarity: 1 },
  { label: "Zeus", value: "Zeus", rarity: 1 },
  { label: "Monkey King", value: "Monkey King", rarity: 1 },
  { label: "Acid", value: "Acid", rarity: 1 },
  { label: "Skeleton", value: "Skeleton", rarity: 1 },
  { label: "Fire", value: "Fire", rarity: 1 },
  { label: "Demon", value: "Demon", rarity: 1 },
  { label: "Angry Viking", value: "Angry Viking", rarity: 1 },
  { label: "Ice", value: "Ice", rarity: 1 },
  { label: "Plague Doctor", value: "Plague Doctor", rarity: 1 },
];
