import { PrismicRichText } from "@prismicio/react";
import React, { useState } from "react";
import {
  AccordionWrapper,
  Header,
  HeaderTitle,
  Content,
  HeaderBorderBottom,
  HeaderWrapper,
  Expand,
  Number,
  HeaderInfoWrapper,
  Answer,
} from "./Accordion.style";

const Accordion = ({ title, content, number, isActive, setIsActive }) => {
  return (
    <AccordionWrapper>
      <Header onClick={() => setIsActive(!isActive)}>
        <Number>{number}</Number>
        <HeaderInfoWrapper>
          <HeaderWrapper>
            <HeaderTitle>{title}</HeaderTitle>
            <Expand>{isActive ? "-" : "+"}</Expand>
          </HeaderWrapper>
          <HeaderBorderBottom />
        </HeaderInfoWrapper>
      </Header>
      <Content isActive={isActive}>
        <Answer>
          <PrismicRichText field={content} />
        </Answer>
      </Content>
    </AccordionWrapper>
  );
};

export default Accordion;
