import styled, { keyframes } from "styled-components/macro";

export const GalleryWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  max-height: 650px;

  margin-top: 50px;

  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const sway2 = keyframes`
  0% { transform: translate(-379px, 0px); }
  100% { transform: translate(379px, 0px); }
`;

export const sway = keyframes`
  0% { transform: translate(0px, 0px); }
  100% { transform: translate(-379px, 0px); }
`;

export const Row = styled.div``;

export const GalleryImage = styled.img`
  border-radius: 20px;
  width: 379px;
  height: 422px;

  animation-direction: alternate;
`;
