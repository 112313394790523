import styled from "styled-components/macro";

export const NftImage = styled.img`
  border-radius: 8px;
  width: 400px;

  @media (max-width: 900px) {
    max-width: 50%;
    width: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 800px;
  max-width: 900px;

  @media (max-width: 900px) {
    width: 100%;
    flex-wrap: wrap;
    min-width: unset;
    align-items: center;
    justify-content: center;
  }
`;

export const Details = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-right: 15px;
  padding: 20px;
  @media (max-width: 900px) {
    margin-top: 0;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-family: "Oswald";
  color: rgb(0, 0, 0);
  font-weight: bold;
  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

export const SolScanLink = styled.a`
  border-radius: 50%;
  background-color: rgb(0, 0, 0, 0.1);
  display: block;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);
  width: calc(100% - 20px);
  margin: 0 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
`;

export const CloseButton = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  float: right;
  cursor: pointer;
  margin: 20px;
`;

export const AttributesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

export const Attribute = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: calc(50% - 20px);
  align-items: center;
  padding: 10px 0;
  margin: 0 10px;
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);

  @media (max-width: 900px) {
    width: calc(50% - 20px);
  }
`;

export const AttrImg = styled.img``;

export const AttrDetails = styled.div``;

export const AttrLabel = styled.div`
  font-size: 12px;
  font-family: "Oswald";
  color: rgba(0, 0, 0, 0.702);
  font-weight: bold;
  text-transform: uppercase;
`;

export const AttrValue = styled.div`
  font-size: 16px;
  font-family: "Poppins";
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

export const ImgWrapper = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
