import React from "react";
import {
  MeetTheTeamWrapper,
  Title,
  TeamWrapper,
  NftImage,
  Subtitle,
  MemberTitleWrapper,
  MemberTitle,
  SocialIcon,
  SocialLink,
  Description,
  MemberWrapper,
} from "./MeetTheTeam.style";
// - MoonWalker
// - Pyrros
// - Boink
// - Johnny
// - Micho
// - Brando
const MeetTheTeam = ({ data }) => {
  return (
    <MeetTheTeamWrapper id="meettheteam">
      <Title>{data.team_title}</Title>
      <TeamWrapper>
        {data.members.map((member) => {
          return (
            <MemberWrapper key={member.name}>
              <NftImage src={member.image.url} />
              <MemberTitleWrapper>
                <MemberTitle>{member.name}</MemberTitle>
                {member.twitter_link.url && (
                  <SocialLink target="_blank" href={member.twitter_link.url}>
                    <SocialIcon src="twitter.png" />
                  </SocialLink>
                )}
              </MemberTitleWrapper>
              {member.role && <Subtitle>{member.role}</Subtitle>}
              <Description></Description>
            </MemberWrapper>
          );
        })}
      </TeamWrapper>
    </MeetTheTeamWrapper>
  );
};

export default MeetTheTeam;
