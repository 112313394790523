import React from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  CloseButton,
  Content,
  NftImage,
  Details,
  Title,
  Header,
  SolScanLink,
  AttributesWrapper,
  Attribute,
  AttrImg,
  AttrDetails,
  AttrLabel,
  AttrValue,
  ImgWrapper,
} from "./DetailModal.style";
import useWindowSize from "../../../hooks/useWindowSize";

const colors = {
  Gray: "#b1b2ac",
  "Dark Blue": "#3a667f",
  Mustard: "#c6b838",
  Yellow: "#e4e2aa",
  Purple: "#9f7aa1",
  Aquamarine: "#18e8b7",
  Orange: "#f1952d",
  "Baby Blue": "#a1e6f6",
  "Space Pirate": "",
  Reaper: "#355f78",
  Diamond: "#aceaf1",
  Zeus: "#31cbe0",
  "Monkey King": "#e2e0a7",
  Acid: "#18e6b7",
  Skeleton: "#9e799f",
  Fire: "#ee972c",
  Demon: "#cbb8e6",
  "Angry Viking": "#a1e6f6",
  Ice: "#30cadf",
  "Plague Doctor": "#b1b2ac",
};

const attrImgs = {
  Background: "modal-bg.png",
  Hat: "modal-hat.png",
  Earrings: "modal-earrings.png",
  Mouth: "modal-mouth.png",
  Eyes: "modal-eyes.png",
  Fur: "modal-fur.png",
  Clothes: "modal-clothes.png",
};

const DetailModal = ({ modalIsOpen, setIsOpen, data }) => {
  const backgroundAttr = data.attributes.find(
    (item) => item.trait_type === "Background"
  ).value;

  const size = useWindowSize();
  const isTabPort = size.width < 900;

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: backgroundAttr ? colors[backgroundAttr] : "black",
      border: "none",
      color: "white",
      padding: "0",
      zIndex: "101",
      borderRadius: "20px",
      maxWidth: isTabPort ? "600px" : "",
      maxHeight: "100vh",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: "100",
    },
  };

  if (modalIsOpen) window.document.body.classList.add("no-scroll");

  const handleCloseModal = () => {
    window.document.body.classList.remove("no-scroll");
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      contentLabel="NFT Detail Modal"
      style={customModalStyles}
      ariaHideApp={false}
    >
      <CloseButton onClick={handleCloseModal}>
        <FontAwesomeIcon color="black" size="lg" icon="times" />
      </CloseButton>
      <Content>
        <NftImage src={data.src} />
        <Details>
          <Header>
            <Title>{data.name}</Title>
            <SolScanLink
              href={`https://solscan.io/token/${data.mint}`}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon color="black" size="sm" icon="link" />
            </SolScanLink>
          </Header>
          <AttributesWrapper>
            {data.attributes.map((attr) => {
              return (
                <Attribute key={attr.trait_type}>
                  <ImgWrapper>
                    <AttrImg
                      width="auto"
                      height="22px"
                      src={attrImgs[attr.trait_type]}
                    />
                  </ImgWrapper>

                  <AttrDetails>
                    <AttrLabel>{attr.trait_type}</AttrLabel>
                    <AttrValue>{attr.value}</AttrValue>
                  </AttrDetails>
                </Attribute>
              );
            })}
          </AttributesWrapper>
        </Details>
      </Content>
    </Modal>
  );
};

export default DetailModal;
