import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const StoryWrapper = styled.div`
  background-image: url(story-background.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: 100%;
  height: 1200px;
  max-height: 1200px;

  display: flex;
  justify-content: flex-end;

  @media (max-width: 450px) {
    min-height: 100vh;
    background-image: url(story-mobile.png);
  }
`;

export const RightWrapper = styled.div`
  width: 50%;
  max-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 450px) {
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
  }
`;

export const InfoWrapper = styled.div`
  max-width: 580px;
  padding: 20px;

  @media (max-width: 450px) {
    width: 100%;
    max-width: calc(100% - 40px);
  }
`;

export const TitleImage = styled.img`
  max-width: calc(100% - 40px);
  height: auto;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  font-size: 18px;
  font-family: "Poppins";
  color: rgba(255, 255, 255, 0.8);
  margin: 20px 0;
  @media (max-width: 450px) {
    font-size: 16px;
  }
`;

export const Button = styled(Link)`
  display: block;
  text-decoration: none;
  padding: 10px 25px;
  border-radius: 5px;
  width: fit-content;

  font-size: 16px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;

  background-image: url(orange-background.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: fit;
  margin-top: 30px;
  z-index: 900;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

export const SnowflakeWrapper = styled.div`
  position: absolute;
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-height: 100%;

  @media (max-width: 450px) {
    width: 100%;
    height: 50%;
    bottom: 0;
    min-height: 50%;
  }
`;
