import styled from "styled-components/macro";

export const StoryWrapper = styled.div`
  background-image: url(${({ imageUrl }) => imageUrl});

  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: 100%;
  height: calc(100vh - 140px);
  position: relative;
  overflow: hidden;

  @media (max-width: 450px) {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      ),
      url(story1-mobile.png);
    background-position: bottom;
  }
`;
export const InfoWrapper = styled.div`
  max-width: 50%;
  padding: 20px 50px;

  @media (max-width: 450px) {
    max-width: 100%;
  }
`;

export const MainInfoWrapper = styled.div`
  max-width: 580px;
  padding: 20px;
  @media (max-width: 450px) {
    padding: 20px 50px;
  }
`;

export const Title = styled.h1`
  font-size: 85px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 12px;
  @media (max-width: 450px) {
    font-size: 60px;
  }
`;

export const Description = styled.div`
  font-size: 18px;
  font-family: "Poppins";
  color: rgba(255, 255, 255, 0.8);

  @media (max-width: 450px) {
    font-size: 16px;
  }
`;
export const StoryImg = styled.img``;
export const LeftWrapper = styled.div`
  width: 50%;
  max-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 450px) {
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
  }
`;

export const StoryItem = styled.div`
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: 100%;
  min-height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 450px) {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      ),
      url(${({ imageUrl }) => imageUrl});
    background-position: bottom;
    min-height: 100vh;
    justify-content: flex-start;
  }
`;

export const SnowflakeWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-height: calc(100vh - 140px);
`;
