import styled, { css } from "styled-components/macro";

export const HomeWrapper = styled.div`
  background-image: url(home-background.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: 100%;
  min-height: calc(100vh - 140px);

  @media (max-width: 450px) {
    margin-top: 10px;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      ),
      url(home-background.png);
  }
`;

export const LeftWrapper = styled.div`
  width: 50%;
  max-width: 50%;
  min-height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 450px) {
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
  }
`;

export const InfoWrapper = styled.div`
  max-width: 580px;
  padding: 20px;
`;

export const TitleImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const Description = styled.div`
  margin-top: 1rem;
  max-width: 530px;
  font-size: 18px;

  @media (max-width: 450px) {
    font-size: 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  margin-top: 2.5rem;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const Button = styled.a`
  display: block;
  text-decoration: none;
  border: 4px solid white;
  padding: 10px 25px;
  border-radius: 5px;

  font-size: 14px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;

  width: fit-content;

  @media (max-width: 450px) {
    font-size: 14px;
    padding: 8px 15px;
    min-width: 200px;
  }

  &:hover {
    background-image: url(orange-background.png);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: fit;
  }
`;
