import styled from "styled-components/macro";

export const MerchWrapper = styled.div`
  background-image: url(merch-bg.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: center;
  width: 100%;
  min-height: calc(100vh - 140px);
`;

export const BannerWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #f89927;
`;

export const ApeImg1 = styled.img`
  @media (max-width: 450px) {
    height: 180px;
  }
`;

export const ApeImg2 = styled.img`
  @media (max-width: 450px) {
    height: 180px;
  }
`;

export const ComingSoon = styled.h2`
  font-size: 70px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 450px) {
    font-size: 40px;
  }
`;
