import styled from "styled-components/macro";

export const RoadmapWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
`;
export const Title = styled.h2`
  font-size: 70px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: 450px) {
    font-size: 40px;
  }
`;

export const VideoWrapper = styled.div`
  max-width: 100%;
  max-width: 1200px;
  position: relative;
  height: 200px;

  @media (max-width: 450px) {
    display: none;
  }
`;

export const MapWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Line = styled.div`
  width: 4px;
  height: 50px;
  background-color: #ff9921;

  background: rgb(255, 153, 33, , 0.5);
  background: linear-gradient(
    to bottom,
    rgba(255, 153, 33, 0.1) 0%,
    rgba(255, 153, 33, 0.9682466736694678) 46%,
    rgba(1255, 153, 33, 0.1) 100%
  );

  z-index: -1;

  @media (max-width: 450px) {
    max-height: 140px;
  }
`;

export const VideoBorder = styled.img`
  position: absolute;
`;

export const RmImageWrapper = styled.img`
  width: 60vw;
  border-radius: 5px;
  border: 5px solid #ff9921;
  box-shadow: 0px 0px 10px #ff9921;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    flex-wrap: wrap;
  }
`;

export const Button = styled.a`
  margin-top: 20px;
  display: block;
  text-decoration: none;
  border: 4px solid #ff9921;
  box-shadow: 0px 0px 10px #ff9921;
  padding: 10px 25px;
  border-radius: 5px;

  font-size: 14px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;

  width: 220px;

  @media (max-width: 450px) {
    font-size: 14px;
    padding: 8px 15px;
    min-width: 200px;
  }

  &:hover {
    background-image: url(orange-background.png);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: fit;
  }
`;
