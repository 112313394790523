import styled from "styled-components/macro";

export const GalleryWrapper = styled.div`
  display: flex;
  gap: 50px;
  margin: 0 100px;
  margin-top: 50px;
  min-height: calc(100vh - 140px);
  @media (max-width: 900px) {
  }
  @media (max-width: 900px) {
    flex-wrap: wrap;
    margin: 0 20px;
  }
`;
export const Images = styled.div`
  flex-grow: 1;
`;

export const ImagesHeader = styled.div`
  border-bottom: 1px solid white;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 100%;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const ImagesTitle = styled.h2`
  font-size: 32px;
  font-family: "Oswald";
  letter-spacing: 2px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const TabButton = styled.button`
  font-size: 15px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  height: fit-content;
  background-color: #2b2b2b;
  border: none;
  border-radius: 8px;

  padding: 10px 25px;

  background-image: ${({ isActive }) =>
    isActive ? "url(orange-background.png)" : ""};
  cursor: pointer;
`;

export const ItemWrapper = styled.div`
  width: 23%;
  min-width: 180px;

  @media (max-width: 900px) {
    min-width: unset;
    width: 23%;
  }

  @media (max-width: 450px) {
    width: 47%;
  }
`;

export const NftImage = styled.img`
  width: 100%;
  border-radius: 20px;
  cursor: pointer;
  min-height: 180px;
  min-width: 180px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DetailsWrapper = styled.div`
  margin-top: 15px;
`;

export const ItemTitle = styled.div`
  font-size: 24px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);

  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

export const SolScanLink = styled.a`
  border-radius: 50%;
  background-color: rgb(255, 255, 255, 0.1);
  display: block;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Number = styled.div`
  font-size: 21px;
  font-family: "Oswald";
  color: rgba(255, 255, 255, 0.6);
`;

export const FiltersWrapper = styled.div`
  width: 300px;
  min-width: 300px;
  @media (max-width: 900px) {
    width: 100%;
    margin: 20px 0;
    height: ${({ showFilters }) => (showFilters ? "auto" : "0")};
    visibility: ${({ showFilters }) => (showFilters ? "visible" : "hidden")};
    margin: ${({ showFilters }) => (showFilters ? "20px 0" : "0")};
  }
`;

export const FiltersTitle = styled.h2`
  font-size: 32px;
  font-family: "Oswald";
  letter-spacing: 2px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid white;
  padding-bottom: 15px;
  margin: 0;
`;

export const SelectFilters = styled.div``;

export const SelectedFilters = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SelectedFiltersLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const SelectedFiltersLabel = styled.div`
  font-size: 17px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
`;

export const NumFilters = styled.div`
  border-radius: 50%;
  background-color: rgb(255, 255, 255, 0.1);
  display: block;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 13px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;
`;

export const sortSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "white",
    textAlign: "left",
    backgroundColor: state.isFocused ? "" : "#09080d",
    backgroundImage: state.isFocused ? "url(orange-background.png)" : "",
    fontWeight: "bold",
    fontFamily: "Oswald",
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: "flex",
    border: "none",
    textAlign: "left",
    color: "white",
    fontSize: "17px",
    fontWeight: "bold",
    fontFamily: "Oswald",
    cursor: "pointer",
  }),
  menu: () => ({
    backgroundColor: "#09080d",
    position: "absolute",
    width: "100%",
    zIndex: "1000",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "white" };
  },
};

export const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "red" : "#8e8e8e",
    textAlign: "left",
    backgroundColor: state.isFocused ? "#2e2845" : "#09080d",
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: "flex",
    gap: "20px",
    alignItems: "center",
    border: "none",
    textAlign: "left",
    color: "white",
    fontSize: "17px",
    fontWeight: "bold",
    fontFamily: "Oswald",
    borderBottom: "1px solid rgb(255,255,255,0.5)",
    textTransform: "uppercase",
    paddingTop: "10px",
    paddingBottom: "10px",
    cursor: "pointer",
  }),
  valueContainer: () => ({
    display: "flex",
    flexWrap: "wrap",
  }),
  placeholder: () => ({
    color: "white",
  }),
  menu: () => ({
    backgroundImage: "url(orange-background.png)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "absolute",
    width: "100%",
    zIndex: "1000",
    fontSize: "16px",
    fontFamily: "Oswald",
    color: "white",
    padding: "10px 20px",
    cursor: "pointer",
    maxWidth: "260px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "white" };
  },
  indicatorsContainer: () => ({
    marginLeft: "auto",
    display: "flex",
  }),
};

export const Option = styled.div`
  height: 30px;
  cursor: "pointer";
  text-transform: uppercase;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0;
  justify-content: flex-end;
`;

export const PaginationAction = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? "" : "pointer")};

  color: ${({ disabled }) => (disabled ? "#2b2b2b" : "white")};
`;

export const PageNumber = styled.div``;

export const ClearFilters = styled.button`
  font-size: 12px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  height: fit-content;
  background-color: #2b2b2b;
  border: none;
  border-radius: 8px;

  padding: 8px 20px;
  margin-top: 20px;

  cursor: pointer;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const Tabs = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 900px) {
    justify-content: flex-end;
  }
`;

export const FiltersButton = styled.img`
  display: none;
  @media (max-width: 900px) {
    display: block;
    margin-left: auto;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
`;
