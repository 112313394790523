import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  MobileLogo,
  LinksWrapper,
  ContentWrapper,
  MobileNavBar,
  StyledLink,
  SocialIcons,
  SocialIcon,
  NavBarLink,
  MobileNavMenu,
  MobileLinksWrapper,
  MobileLinkStyle,
  Hr,
  SocialLinksMobile,
  MobileNavLogoWrapper,
  CloseButton,
  HamButton,
  BuyButton,
  LogoWrapper,
  Dropdown,
  DropdownContent,
} from "./Navbar.style";
import { useSinglePrismicDocument } from "@prismicio/react";

const Navbar = () => {
  const size = useWindowSize();
  const isTabPort = size.width < 900;
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) window.document.body.classList.add("no-scroll");
    else window.document.body.classList.remove("no-scroll");
  }, [isOpen]);
  const [prismicNav] = useSinglePrismicDocument("header");
  const location = useLocation();

  const toggleMobileNav = () => {
    setIsOpen(!isOpen);
    window.scrollTo(0, 0);
  };

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  if (!size.width) return <></>;

  const getDesktopLink = (label, link) => {
    if (link.url.includes("www.babyapesocialclub.com"))
      if (link.url.includes("#")) {
        return (
          <NavBarLink
            href={`${link.url.split("www.babyapesocialclub.com", 2)[1]}`}
          >
            {label}
          </NavBarLink>
        );
      } else
        return (
          <StyledLink
            isActive={
              location.pathname ===
              `/${link.url.split("www.babyapesocialclub.com", 2)[1]}`
            }
            onClick={handleLinkClick}
            to={`${link.url.split("www.babyapesocialclub.com", 2)[1]}`}
          >
            {label}
          </StyledLink>
        );
    return (
      <NavBarLink target="_blank" key={link.url} href={link.url}>
        {label}
      </NavBarLink>
    );
  };

  const getMobileLinks = (label, link) => {
    if (!link?.url) return <></>;
    if (link.url.includes("www.babyapesocialclub.com"))
      if (link.url.includes("#")) {
        return (
          <div key={link.url}>
            <a
              style={{ textDecoration: "none" }}
              href={`${link.url.split("www.babyapesocialclub.com", 2)[1]}`}
              onClick={toggleMobileNav}
            >
              <MobileLinkStyle>{label}</MobileLinkStyle>
            </a>
            <Hr />
          </div>
        );
      } else
        return (
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={`${link.url.split("www.babyapesocialclub.com", 2)[1]}`}
              onClick={toggleMobileNav}
            >
              <MobileLinkStyle>{label}</MobileLinkStyle>
            </Link>
            <Hr />
          </div>
        );
    return (
      <div key={link.url}>
        <a
          style={{ textDecoration: "none" }}
          href={link.url}
          target="_blank"
          onClick={toggleMobileNav}
          rel="noreferrer"
        >
          <MobileLinkStyle>{label}</MobileLinkStyle>
        </a>
        <Hr />
      </div>
    );
  };

  const getLogo = (image, link) => {
    return (
      <LogoWrapper>
        <a href={link.url} width="140px">
          <img src={image?.url} width="140px" alt="logo" />
        </a>
      </LogoWrapper>
    );
  };

  const getDropdown = (label, links) => {
    return (
      <Dropdown>
        <span>{label}</span>
        <DropdownContent>
          {links.map((link) => {
            return (
              <NavBarLink key={link.label} href={link.link.url} target="_blank">
                {link.label}
              </NavBarLink>
            );
          })}
        </DropdownContent>
      </Dropdown>
    );
  };

  const getDropdownMobile = (label, links) => {
    return links.map((link) => getMobileLinks(link.label, link.link));
  };

  return (
    <>
      {isTabPort && (
        <MobileNavBar>
          <MobileLogo href="/">
            <img src="logo.png" width="100px" alt="logo" />
          </MobileLogo>
          <HamButton onClick={() => setIsOpen(!isOpen)}>
            <img src="ham-menu.png" width="30px" />
          </HamButton>
        </MobileNavBar>
      )}
      {!isTabPort && (
        <ContentWrapper>
          <SocialIcons>
            <a
              href="https://discord.com/invite/2uNZYSKvsw"
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon src="discord2.png" />
            </a>
            <a
              href="https://twitter.com/BabyApe_SC?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon src="twitter2.png" />
            </a>
          </SocialIcons>
          <LinksWrapper>
            {prismicNav?.data?.body?.map((slice) => {
              if (slice.slice_type === "link")
                return getDesktopLink(slice.primary.label, slice.primary.link);
              else if (slice.slice_type === "logo_link")
                return getLogo(slice.primary.logo1, slice.primary.link);
              else if (slice.slice_type === "dropdown")
                return getDropdown(slice.primary.label, slice.items);
            })}
          </LinksWrapper>

          <SocialIcons>
            <a
              href="https://magiceden.io/marketplace/baby_ape_social_club"
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon src="magic-eden.png" />
            </a>
            <a
              href="https://www.instagram.com/babyapesocialclub/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon src="instagram2.png" />
            </a>
          </SocialIcons>
        </ContentWrapper>
      )}
      <MobileNavMenu isOpen={isOpen}>
        <MobileNavLogoWrapper>
          <img src="logo.png" width="70px" />
          <CloseButton onClick={toggleMobileNav}>
            <FontAwesomeIcon color="white" size="2x" icon="times" />
          </CloseButton>
        </MobileNavLogoWrapper>
        <MobileLinksWrapper>
          <div>
            {prismicNav?.data?.body?.map((slice) => {
              if (slice.slice_type === "link")
                return getMobileLinks(slice.primary.label, slice.primary.link);
              else if (slice.slice_type === "dropdown")
                return getDropdownMobile(slice.primary.label, slice.items);
            })}
          </div>
        </MobileLinksWrapper>
        <SocialLinksMobile>
          <a
            href="https://discord.com/invite/2uNZYSKvsw"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src="discord2.png" />
          </a>
          <a
            href="https://twitter.com/BabyApe_SC?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src="twitter2.png" />
          </a>
          <a
            href="https://magiceden.io/marketplace/baby_ape_social_club"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src="magic-eden.png" />
          </a>
          <a
            href="https://www.instagram.com/babyapesocialclub/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src="instagram2.png" />
          </a>
        </SocialLinksMobile>
      </MobileNavMenu>
    </>
  );
};

export default Navbar;
