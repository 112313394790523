import styled from "styled-components";

export const CollapsibleItemWrapper = styled.div``;

export const Card = styled.div`
  background-image: linear-gradient(
    90deg,
    rgb(5, 4, 0) 0%,
    rgb(21, 20, 17) 52%,
    rgb(12, 11, 9) 64%,
    rgb(3, 2, 0) 100%
  );

  border-radius: 4px;
  padding: 5px 20px;

  width: 400px;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    width: 500px;
  }

  @media (max-width: 450px) {
    width: calc(100% - 40px);
  }
`;

export const MapItemWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const LeftWrapper = styled.div`
  grid-area: 1 / 3 / 2 / 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

export const DetailWrapper = styled.div`
  grid-area: ${({ right }) => (right ? " 1 / 1 / 2 / 3" : "1 / 4 / 2 / 6")};
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  min-height: 120px;
`;

export const MonkeyImg = styled.img`
  height: 80px;
  @media (max-width: 900px) {
    height: auto;
  }
`;

export const ItemTitle = styled.h3`
  font-size: 24px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;
`;

export const Description = styled.div`
  font-size: 18px;
  font-family: "Poppins";
  color: rgba(255, 255, 255, 0.6);
  padding-bottom: 40px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  margin: 0 40px;

  height: ${({ isActive }) => (isActive ? "auto" : "0")};
  opacity: ${({ isActive }) => (isActive ? "1" : "0")};
  padding: ${({ isActive }) => (isActive ? "" : "0")};

  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  padding-left: 40px;
  align-items: center;

  @media (max-width: 900px) {
    padding-right: 20px;
  }
`;

export const CollapsibleBox = styled.button`
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url(${({ imageUrl }) => imageUrl});
  height: 60px;
  width: 61px;
  color: rgb(0, 0, 0);
  text-align: center;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  z-index: 5;

  svg {
    cursor: pointer;
    pointer-events: none;
  }

  div {
    height: fit-content;
    line-height: auto;
  }

  @media (max-width: 900px) {
    width: 31px;
    height: 30px;
  }
`;

export const BorderImg = styled.img`
  position: absolute;
  top: 0;
  left: -20px;
  z-index: 2;

  @media (max-width: 900px) {
    left: 0;
    top: -25px;
    width: 100%;
  }

  @media (max-width: 450px) {
    top: -25px;
    left: 0;
    width: calc(100% - 40px);
    left: 20px;
  }
`;

export const BorderBottomImg = styled.img`
  position: absolute;
  top: -25px;

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: 450px) {
    width: calc(100% - 40px);
    left: 20px;
  }
`;

export const Line = styled.div`
  width: 4px;
  height: 100%;
  background-color: white;
  min-height: 50px;
  background: rgb(172, 172, 172, 0.5);
  background: linear-gradient(
    to bottom,
    rgba(172, 172, 172, 0.1) 0%,
    rgba(78, 78, 78, 0.9682466736694678) 46%,
    rgba(172, 172, 172, 0.1) 100%
  );
`;
