import React from "react";
import {
  BannerWrapper,
  MerchWrapper,
  ApeImg1,
  ApeImg2,
  ComingSoon,
} from "./Merch.style";

const Merch = () => {
  return (
    <MerchWrapper>
      <BannerWrapper>
        <ApeImg1 src="merch-ape-1.png" />
        <ApeImg2 src="merch-ape-2.png" />
      </BannerWrapper>
      <ComingSoon>Coming Soon!</ComingSoon>
    </MerchWrapper>
  );
};

export default Merch;
