import React, { useState } from "react";
import { GlassMagnifier } from "react-image-magnifiers";
import useWindowSize from "../../../hooks/useWindowSize";
import {
  RoadmapWrapper,
  VideoWrapper,
  MapWrapper,
  Line,
  Card,
  Title,
  VideoBorder,
  RmImageWrapper,
  Button,
  ButtonWrapper,
} from "./School.style";

const School = ({ data }) => {
  const size = useWindowSize();
  const isMobile = size.width < 450;
  const isTabPort = size.width < 900 && size.width > 450;
  let width = isMobile ? size.width - 20 : "700";
  const height = isMobile ? "260" : "500";
  const [isActive, setIsActive] = useState(false);
  if (!size.width) return <></>;
  if (!isTabPort && !isMobile) {
    width = "900";
  }

  return (
    <RoadmapWrapper id="school">
      <Title>{data.school_title}</Title>
      <Line />
      <RmImageWrapper
        src={data.school_image.url}
        style={{ objectFit: "cover" }}
      ></RmImageWrapper>

      <ButtonWrapper>
        {data.chapter_links.map((chapter) => {
          return (
            <>
              <Line />
              <Button
                key={chapter.label}
                href={chapter.link.url}
                target="_blank"
              >
                {chapter.label}
              </Button>
            </>
          );
        })}
      </ButtonWrapper>
    </RoadmapWrapper>
  );
};

export default School;
