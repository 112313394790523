import styled from "styled-components/macro";

export const FooterWrapper = styled.div`
  background-color: rgb(0, 0, 0);
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  @media (max-width: 450px) {
    height: 135px;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  @media (max-width: 450px) {
    margin-top: 20px;
  }
`;

export const Logo = styled.img`
  width: 170px !important;
  @media (max-width: 450px) {
    width: 90px !important;
  }
`;

export const SocialIcon = styled.img`
  width: 40px;
  height: auto;
  @media (max-width: 450px) {
    width: 30px;
    height: auto;
  }
`;

export const Hr = styled.hr`
  background-color: rgb(255, 255, 255);
  opacity: 0.05;
  height: 1px;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
`;

export const Conditions = styled.div`
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  color: rgb(127, 127, 127);
  max-width: 100%;
  text-align: center;
  padding-top: 5px;
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;
