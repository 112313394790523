import React from "react";
import Gallery from "./gallery/Gallery";
import Roadmap from "./roadmap/Roadmap";
import {
  InfoWrapper,
  HomeWrapper,
  TitleImage,
  Description,
  ButtonWrapper,
  Button,
  LeftWrapper,
} from "./Home.style";
import Story from "./story/Story";
import MeetTheTeam from "./meet-the-team/MeetTheTeam";
import Faq from "./faq/Faq";
import { PrismicRichText } from "@prismicio/react";
import School from "./school/School";

const Home = ({ data }) => {
  if (!data) return <></>;
  return (
    <>
      <HomeWrapper>
        <LeftWrapper>
          <InfoWrapper>
            <TitleImage src="title.png" />
            <Description>
              <PrismicRichText field={data.about_text}></PrismicRichText>
            </Description>
            <ButtonWrapper>
              {data.buttons.map((button) => {
                return (
                  <Button
                    key={button.label}
                    href={button.link.url}
                    target="_blank"
                  >
                    {button.label}
                  </Button>
                );
              })}
            </ButtonWrapper>
          </InfoWrapper>
        </LeftWrapper>
      </HomeWrapper>
      <Gallery data={data} />
      <Story data={data} />
      <Roadmap data={data} />
      <School data={data} />
      <MeetTheTeam data={data} />
      <Faq data={data} />
    </>
  );
};

export default Home;
