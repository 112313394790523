import React, { useState } from "react";
import Collapsible from "react-collapsible";
import Accordion from "../../../components/accordion/Accordion";
import {
  FaqWrapper,
  Title,
  ContentWrapper,
  AccordionWrapper,
} from "./Faq.style";

const Faq = ({ data }) => {
  const [isActive, setIsActive] = useState(0);
  const handleSetActive = (index) => {
    if (isActive === index) setIsActive(-1);
    else setIsActive(index);
  };
  return (
    <FaqWrapper id="faq">
      <ContentWrapper>
        <Title>{data.faq_title}</Title>
        <AccordionWrapper>
          {data.faq.map((item, index) => {
            return (
              <Accordion
                key={item.question}
                title={item.question}
                content={item.answer}
                number={`${String(index + 1).padStart(2, "0")}.`}
                isActive={isActive === index}
                setIsActive={() => handleSetActive(index)}
              />
            );
          })}
        </AccordionWrapper>
      </ContentWrapper>
    </FaqWrapper>
  );
};

export default Faq;
