import styled from "styled-components/macro";

export const AccordionWrapper = styled.div`
  max-width: 600px;
  margin: 2rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Header = styled.div`
  display: flex;
  cursor: pointer;
  gap: 15px;
  margin: 0 5px;
  min-height: 51px;
  width: 100%;

  &:hover {
    color: rgba(254, 153, 34);
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  font-size: 20px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;
  &:hover {
    color: rgba(254, 153, 34);
  }
`;

export const HeaderBorderBottom = styled.div`
  background-color: rgb(255, 255, 255);
  opacity: 0.502;
  height: 1px;
  width: 100%;
  margin-top: 20px;
`;

export const Content = styled.div`
  background-color: #39b9d2;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  height: ${({ isActive }) => (isActive ? "auto" : "0")};
  opacity: ${({ isActive }) => (isActive ? "1" : "0")};

  background-color: rgb(254, 153, 34);
  box-shadow: 0px 0px 59px 0px rgba(254, 153, 34);
  margin: 5px;
  width: calc(100% - 35px);
  top: 46px;
  left: 15px;

  font-size: 18px;

  @media (max-width: 450px) {
    width: calc(100% - 32px);
    top: 36px;
    font-size: 16px;
  }
`;

export const Answer = styled.div`
  padding: 20px;
`;

export const Expand = styled.div``;

export const Number = styled.div`
  font-size: 14px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;
  width: 20px;
`;

export const HeaderInfoWrapper = styled.div`
  width: 100%;
  min-width: 550px;
  @media (max-width: 450px) {
    width: 100%;
    min-width: unset;
  }
`;
