import React from "react";
import {
  StoryWrapper,
  RightWrapper,
  InfoWrapper,
  TitleImage,
  Description,
  Button,
  ContentWrapper,
  SnowflakeWrapper,
} from "./Story.style";

import "./story.scss";
import { PrismicRichText } from "@prismicio/react";

const Snowflake = (props) => {
  return (
    <p className="Snowflake" id={`item${props.id}`} style={props.style}>
      ●
    </p>
  );
};

const Story = ({ data }) => {
  const snow = () => {
    let animationDelay = "0s";
    let fontSize = "100px";
    let arr = Array.from(
      "Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!!"
    );
    return arr.map((el, i) => {
      animationDelay = `${(Math.random() * 16).toFixed(2)}s`;
      fontSize = `${Math.floor(Math.random() * 10) + 10}px`;
      let style = {
        animationDelay,
        fontSize,
      };
      return <Snowflake key={i} id={i} style={style} />;
    });
  };
  return (
    <ContentWrapper id="story">
      <SnowflakeWrapper>{snow()}</SnowflakeWrapper>
      <StoryWrapper>
        <RightWrapper>
          <InfoWrapper>
            <TitleImage src="story-title.png" />
            <Description>
              <PrismicRichText field={data.story_description} />
            </Description>
            <Button to="/story">{data.story_button_label}</Button>
          </InfoWrapper>
        </RightWrapper>
      </StoryWrapper>
    </ContentWrapper>
  );
};

export default Story;
