import styled from "styled-components/macro";

export const FaqWrapper = styled.div`
  background-image: url(faq-bg.png);
  background-position: bottom; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
  width: auto;
  min-height: 1100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 120px;
  @media (max-width: 450px) {
    background-image: url(faq-mobile.png);
    height: auto;
    background-size: cover;
    justify-content: flex-start;
    background-position: bottom;
    margin-top: 30px;
  }
`;

export const ContentWrapper = styled.div`
  width: 50%;
  max-width: 50%;
  margin-right: 30px;

  @media (max-width: 450px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0 20px;
    margin-bottom: 300px;
  }
`;

export const Title = styled.h2`
  font-size: 70px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;

  @media (max-width: 450px) {
    font-size: 40px;
  }
`;

export const AccordionWrapper = styled.div`
  min-height: 790px;
`;

// export const Accordion = styled(Collapsible)``;
