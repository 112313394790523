import * as prismic from "@prismicio/client";

// Fill in your repository name
export const repositoryName = "https://basc-raffle.prismic.io/api/v2";

export const client = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  accessToken:
    "MC5ZbHUtbGhNQUFDUUFONlFE.Jn0B77-977-9b--_vV4b77-977-9ImASMR7vv70777-9ee-_ve-_ve-_vQLvv70cfe-_vW3vv73vv70E",
});
