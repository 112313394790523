import React from "react";
import {
  StoryItem,
  StoryWrapper,
  LeftWrapper,
  Title,
  Description,
  InfoWrapper,
  MainInfoWrapper,
  SnowflakeWrapper,
  StoryImg,
} from "./Story.style";
import "./bird.scss";
import useWindowSize from "../../hooks/useWindowSize";
import { PrismicRichText } from "@prismicio/react";
const Snowflake = (props) => {
  return (
    <p className="Snowflake" id={`item${props.id}`} style={props.style}>
      ●
    </p>
  );
};

const Story = ({ data }) => {
  const size = useWindowSize();
  const isMobile = size.width < 450;
  const snow = () => {
    let animationDelay = "0s";
    let fontSize = "100px";
    let arr = Array.from(
      "Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!!"
    );
    return arr.map((el, i) => {
      animationDelay = `${(Math.random() * 16).toFixed(2)}s`;
      fontSize = `${Math.floor(Math.random() * 10) + 10}px`;
      let style = {
        animationDelay,
        fontSize,
      };
      return <Snowflake key={i} id={i} style={style} />;
    });
  };
  if (!data) return <></>;
  const firstSection = data.story[0];
  return (
    <>
      <StoryWrapper
        imageUrl={
          isMobile
            ? firstSection.mobile_background_image
            : firstSection.background_image.url
        }
      >
        <div className="bird-container bird-container--one">
          <div className="bird bird--one"></div>
        </div>
        <div className="bird-container bird-container--two">
          <div className="bird bird--two"></div>
        </div>

        <div className="bird-container bird-container--three">
          <div className="bird bird--three"></div>
        </div>

        <div className="bird-container bird-container--four">
          <div className="bird bird--four"></div>
        </div>
        <LeftWrapper>
          <MainInfoWrapper>
            <Title>{data.title}</Title>
            <Description>
              <PrismicRichText field={firstSection.description} />
            </Description>
          </MainInfoWrapper>
        </LeftWrapper>
      </StoryWrapper>

      {data.story.slice(1).map((section, i) => {
        return (
          <StoryItem
            key={i}
            imageUrl={
              isMobile
                ? section.mobile_background_image
                : section.background_image.url
            }
          >
            <InfoWrapper
              style={{
                marginLeft: section.text_side === "Right" ? "auto" : "",
              }}
            >
              <Description>
                <PrismicRichText field={section.description} />
              </Description>
            </InfoWrapper>
          </StoryItem>
        );
      })}
    </>
  );
};

export default Story;
